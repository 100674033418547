<template>
  <v-snackbar
    v-model="notification.show"
    :color="notification.color"
    multi-line
    top
    :timeout="6000"
    dark
  >
    <span class="text-body-1 white--text">
      {{ notification.message }}
    </span>
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        icon
        @click="hideNotification"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import types from '@/types';

export default {
  computed: {
    ...mapState(
      `${types.INVOICE}`, [
        'notification',
      ],
    ),
  },
  methods: {
    ...mapMutations({
      [types.HIDE_NOTIFICATION]: `${types.INVOICE}/${types.HIDE_NOTIFICATION}`,
    }),
    hideNotification() {
      this[types.HIDE_NOTIFICATION]();
    },
  },
};
</script>
