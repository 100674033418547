<template>
  <div class="col-12">
    <fieldset class="wello the-fieldset">
      <legend class="the-legend font-weight-bold grey--text">
        {{ $t('Información del Pago') }} (DOP)
      </legend>

      <v-list dense class="transparent">
        <v-list-item>
          <v-list-item-title class="text-right">
            {{ $t('Total facturas seleccionadas') }}:
          </v-list-item-title>
          <v-list-item-subtitle class="text-right">
            {{totalInvoices|currency}}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="text-right">{{ $t('Total a pagar') }}:</v-list-item-title>
          <v-list-item-subtitle class="text-right">
            {{total|currency}}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item v-if="exchangeRate">
          <v-list-item-title class="text-right">{{ $t('Tasa de cambio') }}:</v-list-item-title>
          <v-list-item-subtitle class="text-right">
            {{ exchangeRate|currency }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </fieldset>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import types from '@/types';

export default {
  computed: {
    ...mapGetters(
      `${types.PAYMENT}`, [
        'totalInvoices',
        'totalAdvancePayments',
        'total',
        // 'exchangeRate',
      ],
    ),
    ...mapGetters(
      `${types.INVOICE}`, ['exchangeRate'],
    ),
  },
};
</script>
