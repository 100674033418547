<template>
  <v-row>
    <v-col>
      <fieldset class="wello the-fieldset">
        <legend class="the-legend font-weight-bold grey--text">
          {{ $t('Estudiante') }}
        </legend>

        <div>
          <v-btn
            x-small
            outlined
            @click.prevent="checkAll"
          >
            {{ $t('Marcar todas') }}
          </v-btn>
          <v-btn
            x-small
            outlined
            @click.prevent="unCheckAll"
          >
            {{ $t('Desmarcar todas') }}
          </v-btn>
        </div>

        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="invoices"
          class="elevation-0"
          :items-per-page="5"
          disable-sort
          :loading-text="$t('Cargando...')"
          :no-data-text="$t('Usted no tiene pago de evaluaciones pendientes.')"
        >
          <template v-slot:[`item.select`]="{ item }">
            <v-checkbox
              v-model="item.checked"
              :id="item.document_number"
              hide-details
              color="primary"
              class="my-2"
            >
            </v-checkbox>
          </template>
          <!-- <template v-slot:[`item.document_number`]="{ item }">
            <v-btn
              @click.prevent="showDocumentModal(item.document_number)"
              text
              small
              color="primary"
              class="px-0"
            >
              {{item.document_number}}
            </v-btn>
          </template> -->
          <!-- <template v-slot:[`item.date`]="{ item }">
            {{ item.date|date }}
          </template> -->
          <template v-slot:[`item.MONTO`]="{ item }">
            {{ item.MONTO|currency }} {{ item.MONEDA }}
          </template>

        </v-data-table>
      </fieldset>
    </v-col>
</v-row>
</template>

<script>
/* eslint-disable no-param-reassign */
import {
  mapState, mapGetters, mapMutations, // mapActions,
} from 'vuex';
import types from '@/types';

export default {

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: '',
          value: 'select',
        },
        { text: this.$t('Centro'), value: 'NOMBRE_CENTRO' },
        { text: this.$t('Año Escolar'), value: 'ANNOESC' },
        { text: this.$t('Estudiante'), value: 'NOMBRES_ESTUD' },
        { text: this.$t('Grado'), value: 'DESC_GRADO' },
        // { text: this.$t('Payment Plan'), value: 'payment_plan' },
        // { text: this.$t('Fees'), value: 'fees' },
        { text: this.$t('Monto'), align: 'end', value: 'MONTO' },
      ];
    },
    ...mapState(
      `${types.INVOICE}`, [
        'invoices',
      ],
    ),
    ...mapState(
      `${types.REGISTER_INVOICES}`, [
        'registerInvoices',
      ],
    ),
    ...mapGetters(
      `${types.INVOICE}`, [
        'selectedInvoices',
      ],
    ),
    ...mapState(
      `${types.PAYMENT}`, [
        'payment',
        'evaluationPaymentStart',
      ],
    ),
    ...mapState(
      `${types.APP}`, [
        'merchant',
      ],
    ),
    selected() {
      return this.invoice.filter((item) => item.checked);
    },
  },

  watch: {
    currentClient: {
      handler() {
        this.getInvoices();
      },
      deep: true,
    },
    selectedInvoices(invoices) {
      this.payment.invoices = invoices;
      const advancepayments = invoices.map((invoice) => {
        const {
          // eslint-disable-next-line camelcase
          description, concept_id, position, ...evaluationPaymentStart
        } = this.evaluationPaymentStart;
        const advancedInvoice = {
          amount: invoice.MONTO_DOP,
          currency: invoice.MONEDA_DOP,
          description: `Student Evaluation (${invoice.NOMBRES_ESTUD})`,
          position: invoice.IDSOLCUPO,
          concept_id: invoice.IDCONCEPTO,
          ...evaluationPaymentStart,
        };
        return advancedInvoice;
      });
      this.payment.advancepayments = advancepayments;
    },
    showInvoiceDocumentModal() {
      if (!this.showInvoiceDocumentModal) {
        this.documentNumber = null;
      }
    },
  },

  methods: {
    ...mapMutations({
      [types.SET_INVOICES]: `${types.INVOICE}/${types.SET_INVOICES}`,
    }),
    checkAll() {
      this.invoices.forEach((item) => {
        if (!item.checked) {
          item.checked = true;
        }
      });
    },
    unCheckAll() {
      this.invoices.forEach((item) => {
        if (item.checked) {
          item.checked = false;
        }
      });
    },
    showDocumentModal(documentNumber) {
      this.documentNumber = documentNumber;
      this.showInvoiceDocumentModal = true;
    },
    totalAmount(item) {
      item.amount = item.fee.reduce((a, b) => a + parseFloat(b.ct.MONTO_CUOTA), 0);
      item.amount_dop = item.fee.reduce((a, b) => a + parseFloat(b.ct.MONTO_DOP), 0);
    },
    selectDefault() {
      this.$nextTick(() => {
        this.invoices.map((item) => {
          if (item.PLAN.length === 1) {
            item.selected_payment_plan = this.registerInvoices[0].payment_plan[0].value;
            this.getFees(item);
          }
          return false;
        });
      });
    },
  },

  destroyed() {
    this[types.SET_INVOICES]();
  },
};
</script>
<style scoped>
    .v-btn:not(.v-btn--round).v-size--x-small {
    margin-right: 4px;
    font-size: .625rem;
}
</style>
