<template>
<v-row>
<v-col cols="12">
<v-card
    tile
  >
    <v-app-bar
      color="primary"
      dark
      height="55"
    >
      <v-toolbar-title>
        {{ $t('Pagar evaluación') }}
      </v-toolbar-title>
      <slot></slot>
    </v-app-bar>
  </v-card>

<v-card
        flat
        outlined
        class="mt-2"
      >
        <v-container fluid>
          <invoices-component />
          <v-row>
            <v-col cols-sm="6">
              <v-row>
                <selected-invoices-component />
              </v-row>
            </v-col>
            <v-col cols-sm="6">
              <v-row>
                <payment-data-component />
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          fluid
        >
          <v-row>
            <v-col
              class="text-right"
            >
              <v-btn
                @click.prevent="savePaymentAttempt"
                :disabled="!enablePayButton()"
                color="success"
              >
                {{ $t('Hacer pago') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
</v-col>
<Busy />
<Notification />
</v-row>
</template>
<script>

import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import types from '@/types';
import InvoicesComponent from '../components/InvoicesComponent.vue';
import SelectedInvoicesComponent from '../components/SelectedInvoicesComponent.vue';
import PaymentDataComponent from '../components/PaymentDataComponent.vue';
import Busy from '../components/Busy.vue';
import Notification from '../components/Notification.vue';

export default {

  components: {
    InvoicesComponent,
    SelectedInvoicesComponent,
    PaymentDataComponent,
    Busy,
    Notification,
  },

  data() {
    return {
      id: this.$route.params.idpay,
    };
  },

  computed: {
    ...mapState(
      `${types.PAYMENT}`, ['payment'],
    ),
    ...mapState(
      `${types.INVOICE}`, ['invoices'],
    ),
    ...mapGetters(
      `${types.PAYMENT}`, ['total'],
    ),
  },
  methods: {
    ...mapActions({
      [types.GET_INVOICES]: `${types.INVOICE}/${types.GET_INVOICES}`,
    }),
    ...mapActions({
      [types.START_PAYMENT]: `${types.PAYMENT}/${types.START_PAYMENT}`,
      [types.SAVE_PAYMENT_ATTEMPT]: `${types.PAYMENT}/${types.SAVE_PAYMENT_ATTEMPT}`,
    }),
    ...mapMutations(
      `${types.INVOICE}`, [
        types.SHOW_BUSY,
        types.HIDE_BUSY,
        types.SHOW_ERROR,
      ],
    ),
    enablePayButton() {
      if (this.procesing) {
        return false;
      } if (this.total > 0) {
        return true;
      }
      return false;
    },
    savePaymentAttempt() {
      this.procesing = true;
      this[types.SHOW_BUSY]('Processing payment...');
      this[types.SAVE_PAYMENT_ATTEMPT]()
        .then(() => {
          this.$router.push({ name: 'Pay', params: { id: this.payment.id } });
        })
        .catch(() => {
          this[types.SHOW_ERROR]('Error processing payment.');
        })
        .finally(() => {
          this[types.HIDE_BUSY]();
        });
    },
  },
  created() {
    const params = {
      idhash: this.id,
    };
    this[types.GET_INVOICES]({ params });
  },
  mounted() {
    this[types.START_PAYMENT]()
      .then(() => {
      });
  },

};
</script>
<style>
.wello {
  border: 1.3px solid #e3e3e3 !important;
  padding-top: 19px;
  padding-left: 3px;
  margin-bottom: 20px;
}

.the-legend {
  border-style: none;
  border-width: 0;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: -10px;

  width:inherit; /* Or auto */
  padding:0 10px; /* To give a bit of padding on the left and right */
  border-bottom:none;
}
.the-fieldset {
  /* border: 2px groove threedface #444; */
  -webkit-box-shadow:  0px 0px 0px 0px #000;
  box-shadow:  0px 0px 0px 0px #000;
}
</style>
