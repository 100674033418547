<template>
    <v-dialog
      v-model="busy.show"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{busy.message}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import types from '@/types';

export default {
  computed: {
    ...mapState(
      `${types.INVOICE}`, [
        'busy',
      ],
    ),
  },
};
</script>
