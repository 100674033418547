<template>
  <div class="col-12">
    <fieldset class="wello the-fieldset">
      <legend class="the-legend font-weight-bold grey--text">
        {{ $t('Evaluaciones selecionadas') }}
      </legend>
      <div class="div-selected">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="">{{ $t('Centro') }}</th>
                <th class="text-right">{{ $t('Monto') }}</th>
                <th class="text-right">{{ $t('Moneda') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in payment.invoices" :key="item.IDSOLCUPO">
                <td class="col-xs-7">
                  {{item.NOMBRE_CENTRO}}
                </td>
                <td class="col-xs-3 text-right">
                  {{item.MONTO_DOP|currency}}
                </td>
                <td class="col-xs-2 text-right">
                  {{item.MONEDA_DOP}}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import types from '@/types';

export default {
  computed: {
    ...mapState(
      `${types.PAYMENT}`, [
        'payment',
      ],
    ),
  },

};
</script>
<style scoped>
.div-selected {
  min-height: 266px;
  max-height: 266px;
  overflow-y: scroll;
}
</style>
