import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c('fieldset',{staticClass:"wello the-fieldset"},[_c('legend',{staticClass:"the-legend font-weight-bold grey--text"},[_vm._v(" "+_vm._s(_vm.$t('Estudiante'))+" ")]),_c('div',[_c(VBtn,{attrs:{"x-small":"","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.checkAll.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Marcar todas'))+" ")]),_c(VBtn,{attrs:{"x-small":"","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.unCheckAll.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Desmarcar todas'))+" ")])],1),_c(VDataTable,{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.invoices,"items-per-page":5,"disable-sort":"","loading-text":_vm.$t('Cargando...'),"no-data-text":_vm.$t('Usted no tiene pago de evaluaciones pendientes.')},scopedSlots:_vm._u([{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c(VCheckbox,{staticClass:"my-2",attrs:{"id":item.document_number,"hide-details":"","color":"primary"},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})]}},{key:"item.MONTO",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.MONTO))+" "+_vm._s(item.MONEDA)+" ")]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }